<template>
  <div class="timer">
    <el-card class="box-card">
      <div slot="header" class="timer-header">
        <div class="title">
          <div class="head-title">
            Timer -
            <span v-if="isCustom === 0">
              Select A Test
            </span>
            <span v-if="isCustom === 1">
              Set Your Timer
            </span>
          </div>
        </div>
        <div class="change-version">
          <el-button v-if="isCustom === 1" @click="changeVersion(0)"
            ><i class="el-icon-refresh"></i> Select A Test</el-button
          >
          <el-button v-if="isCustom === 0" @click="changeVersion(1)"
            ><i class="el-icon-refresh"></i> Set Your Timer</el-button
          >
        </div>
      </div>
      <div class="timer-body">
        <div class="subjects_choose" v-if="isCustom === 0">
          <el-radio-group v-model="essay" class="hasEssay" v-show="false">
            <el-radio-button label="0">Without Essay</el-radio-button>
            <el-radio-button label="1">With Essay</el-radio-button>
          </el-radio-group>
          <el-tabs v-model="activeSelectTest" type="border-card">
            <el-tab-pane label="SAT" name="SAT">
              <div class="test-list">
                <!-- <div v-for="(item, index) in tests" :key="index">
                  <el-radio v-model="test" :label="item">
                    {{ item.full_name }} ({{ item.title }})
                  </el-radio>
                </div> -->
                <el-select v-model="test" filterable style="width:100%">
                  <el-option
                    v-for="(item, index) in tests" 
                    :key="index"
                    :label="item.full_name+' ('+ item.title +')'"
                    :value="index">
                  </el-option>
                </el-select>
              </div>
            </el-tab-pane>
            <el-tab-pane label="ACT" name="ACT">
              <div class="test-list">
                <!-- <div v-for="(item, index) in tests" :key="index">
                  <el-radio v-model="test" :label="item">
                    {{ item.full_name }} ({{ item.title }})
                  </el-radio>
                </div> -->
                 <el-select v-model="test" filterable style="width:100%">
                  <el-option
                    v-for="(item, index) in tests" 
                    :key="index"
                    :label="item.full_name+' ('+ item.title +')'"
                    :value="index">
                  </el-option>
                </el-select>
              </div>
            </el-tab-pane>
          </el-tabs>
          <div class="start text-center">
            <el-button @click="startSelectTest" type="success">Start</el-button>
          </div>
        </div>
        <div class="subjects_custom" v-if="isCustom === 1">
          <el-tabs
            v-model="activeTest"
            @tab-click="selectTest"
            type="border-card"
          >
            <el-tab-pane
              v-for="test in testList"
              :key="test"
              :label="test"
              :name="test"
            >
              <div class="test-list">
                <div v-for="(timer, index) in timer" :key="index" class="list row">
                  <div class="col-md-1">
                    <div style="line-height:40px">
                      <el-button
                        @click="removeSection(index)"
                        type="danger"
                        icon="el-icon-delete"
                        size="mini"
                        circle
                      ></el-button>
                    </div>
                  </div>
                  <div class="col-md-1 col-sm-12">
                    <h6 style="margin-right:20px;line-height:40px;margin:0">Section:</h6>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <el-select v-model="timer.title" style="width:100%">
                      <el-option
                        v-for="item in timerSelect[test]"
                        :key="item.title"
                        :label="item.name"
                        :value="item.title"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div class="col-md-1 col-sm-12">
                    <h6 style="margin-right:20px;line-height:40px;margin:0">Rest:</h6>
                  </div>
                  <div class="col-md-3 col-sm-12">
                     <el-input
                        placeholder="请输入内容"
                        v-model="timer.restm"
                        type="number"
                        style="width:100%;"
                      >
                        <template slot="append">Minutes</template>
                      </el-input>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <el-input
                      placeholder="请输入内容"
                      v-model="timer.rests"
                      type="number"
                      style="width:100%"
                    >
                      <template slot="append">Seconds</template>
                    </el-input>
                  </div>
                  <div class=" col-sm-12">
                   <hr />
                  </div>
                </div>
              </div>
              <el-button
                @click="addSection"
                type="success"
                icon="el-icon-plus"
                size="small"
                circle
              ></el-button>
            </el-tab-pane>
          </el-tabs>
          <div class="start text-center">
            <el-button @click="startTest" type="success">Start</el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import TOEFL from "@/apis/tests.js";
export default {
  components: {},
  mixins: [],
  props: ["hasEssay", "selectTestType", "examId"],
  data() {
    return {
      activeSelectTest: this.selectTestType,
      activeTest: "SAT",
      isCustom: 0,
      test: "",
      essay: this.hasEssay,
      tests: [],
      testList: ["SAT", "ACT"],
      SAT: [
        {
          title: "R",
          restm: 10,
          rests: 0
        },
        {
          title: "G",
          restm: 0,
          rests: 10
        },
        {
          title: "M",
          restm: 5,
          rests: 0
        },
        {
          title: "MC",
          restm: 0,
          rests: 0
        }
      ],
      ACT: [
        {
          title: "E",
          restm: 0,
          rests: 10
        },
        {
          title: "M",
          restm: 5,
          rests: 0
        },
        {
          title: "R",
          restm: 0,
          rests: 10
        },
        {
          title: "S",
          restm: 0,
          rests: 10
        },
        {
          title: "W",
          restm: 0,
          rests: 10
        }
      ],
      timerSelect: {
        SAT: {
          R: {
            title: "R",
            name: "Reading (65 Minutes) ",
            timer: 65 * 60,
            rest: 60 * 10
          },
          G: {
            title: "G",
            name: "Grammar (35 Minutes) ",
            timer: 35 * 60,
            rest: 10
          },
          M: {
            title: "M",
            name: "Math without calculator (25 Minutes)",
            timer: 25 * 60,
            rest: 60 * 5
          },
          MC: {
            title: "MC",
            name: "Math with calculator (55 Minutes)",
            timer: 55 * 60,
            rest: 0
          },
          E: {
            title: "E",
            name: "Essay (50 Minutes)",
            timer: 50 * 60,
            rest: 0
          }
        },
        ACT: {
          E: {
            title: "E",
            name: "English (45 Minutes) ",
            timer: 45 * 60,
            rest: 10
          },
          M: {
            title: "M",
            name: "Math (65 Minutes) ",
            timer: 65 * 60,
            rest: 60 * 10
          },
          R: {
            title: "R",
            name: "Reading (35 Minutes) ",
            timer: 35 * 60,
            rest: 10
          },
          S: {
            title: "S",
            name: "Science (35 Minutes) ",
            timer: 35 * 60,
            rest: 60 * 5
          },
          W: {
            title: "W",
            name: "Essay (40 Minutes) ",
            timer: 40 * 60,
            rest: 60 * 10
          }
        }
      },
      timer: []
    };
  },
  computed: {},
  watch: {
    activeSelectTest() {
      this.getTests();
    },
    hasEssay(value) {
      this.essay = value;
    }
  },

  mounted() {
    this.timer = this[this.activeTest];
    this.getTests();
  },

  methods: {
    async getTests() {
      let tests = {};
      if (this.activeSelectTest == "SAT") {
        tests = await TOEFL.getSats();
        tests = tests["sats"];
      } else if (this.activeSelectTest == "ACT") {
        tests = await TOEFL.getActs();
      }
      this.tests = tests;
    },
    changeVersion(value) {
      this.isCustom = value;
    },
    addSection() {
      this.timer.push({
        title: this.timerSelect[this.activeTest].R.title,
        rest: this.timerSelect[this.activeTest].R.rest
      });
    },
    removeSection(index) {
      this.timer.splice(index, 1);
    },
    startSelectTest() {
      let subjects = this.tests[this.test].subjects;
      let timer = [];
      let timerSelect = this.timerSelect[this.activeSelectTest];
      subjects.forEach(e => {
        let title = e.name;
        if (this.activeSelectTest === "SAT") {
          switch (title) {
            case "reading":
              title = "R";
              break;
            case "math":
              title = "M";
              break;
            case "writing":
              title = "G";
              break;
            case "math calculator":
              title = "MC";
              break;
            case "essay":
              title = "E";
              break;
          }
        } else {
          switch (title) {
            case "english":
              title = "E";
              break;
            case "math":
              title = "M";
              break;
            case "essay":
              title = "W";
              break;
            case "science":
              title = "S";
              break;
            case "reading":
              title = "R";
              break;
          }
        }
        if(!(this.activeSelectTest === "SAT" &&  title === "E")){
          timer.push({
            title: title,
            time: timerSelect[title].timer,
            rest: timerSelect[title].rest
          });
        }
      });

      this.$parent.getTimer(this.activeSelectTest, timer);
    },
    startTest() {
      let timer = [];
      let timerSelect = this.timerSelect[this.activeTest];
      this.timer.forEach(e => {
        if(!(this.activeTest === "SAT" &&  e.title === "E")){
          timer.push({
            title: e.title,
            time: timerSelect[e.title].timer,
            rest: parseInt(e.restm) * 60 + parseInt(e.rests)
          });
        }
      });
      console.log(timer);
      this.$parent.getTimer(this.activeTest, timer);
    },
    selectTest() {
      this.timer = this[this.activeTest];
    }
  }
};
</script>

<style scoped>
.box-card {
  margin: 30px 0;
}
.timer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}
.head-title {
  font-size: 20px;
  font-weight: 500;
  color: #43a06b;
}
.hasEssay {
  margin: 0 0 15px 0;
}
.test-list .list {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
}
.start {
  margin-top: 15px;
}
@media screen and (max-width: 768px ) { 
  .box-card {
    margin: 15px 0;
  }
  ::v-deep .el-card__body {
    padding:10px
  }
  ::v-deep .el-card__header {
    padding:10px
  }
  .timer-header {
    flex-direction: column;
  }
  .timer-header .title {
    margin-bottom: 10px;
  }
}
</style>
