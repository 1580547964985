<template>
  <div class="container">
    <select-timer
      v-if="startTest == 0"
      :hasEssay="hasEssay"
      :selectTestType="testType"
      :examId="examId"
    ></select-timer>
    <start-test
      v-if="startTest == 1"
      :timer="timer"
      hasEssay="0"
      :examId="examId"
      :type="testType"
      :enrollmentId="enrollmentId"
      :userExamId="userExamId"
    ></start-test>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import enrollmentApi from "@/apis/enrollment";
import SelectTimer from "@/views/tests/timer/SelectTimer.vue";
import StartTest from "@/views/tests/timer/StartTest.vue";

export default {
  metaInfo() {
    return {
      title: "Timer - Ivy-Way Academy"
    };
  },

  components: {
    SelectTimer,
    StartTest
  },

  mixins: [],

  props: [],
  data() {
    return {
      timer: [],
      testType: "ACT",
      hasEssay: "0",
      examId: 0,
      user_exam_id: 0,
      timerSelect: {
        SAT: {
          R: {
            title: "R",
            name: "Reading (65 Minutes) ",
            timer: 65 * 60,
            rest: 60 * 10
          },
          G: {
            title: "G",
            name: "Grammar (35 Minutes) ",
            timer: 35 * 60,
            rest: 10
          },
          M: {
            title: "M",
            name: "Math without calculator (25 Minutes)",
            timer: 25 * 60,
            rest: 60 * 5
          },
          MC: {
            title: "MC",
            name: "Math with calculator (55 Minutes)",
            timer: 55 * 60,
            rest: 0
          }
          // E: {
          //   title: "E",
          //   name: "Essay (50 Minutes)",
          //   timer: 50 * 60,
          //   rest: 60 * 10
          // }
        },
        ACT: {
          E: {
            title: "E",
            name: "English (45 Minutes) ",
            timer: 45 * 60,
            rest: 10
          },
          M: {
            title: "M",
            name: "Math (65 Minutes) ",
            timer: 65 * 60,
            rest: 60 * 10
          },
          R: {
            title: "R",
            name: "Reading (35 Minutes) ",
            timer: 35 * 60,
            rest: 10
          },
          S: {
            title: "S",
            name: "Science (35 Minutes) ",
            timer: 35 * 60,
            rest: 60 * 5
          }
          // W: {
          //   title: "W",
          //   name: "Essay (40 Minutes) ",
          //   timer: 40 * 60,
          //   rest: 60 * 10
          // }
        }
      },
      SAT: [
        {
          title: "R",
          restm: 5,
          rests: 0
        },
        {
          title: "G",
          restm: 5,
          rests: 0
        },
        {
          title: "M",
          restm: 5,
          rests: 0
        },
        {
          title: "MC",
          restm: 5,
          rests: 0
        },
        {
          title: "E",
          restm: 5,
          rests: 0
        }
      ],
      ACT: [
        {
          title: "E",
          restm: 0,
          rests: 10
        },
        {
          title: "M",
          restm: 5,
          rests: 0
        },
        {
          title: "R",
          restm: 0,
          rests: 10
        },
        {
          title: "S",
          restm: 0,
          rests: 10
        },
        {
          title: "W",
          restm: 0,
          rests: 10
        }
      ],
      startTest: 0
    };
  },
  computed: {
    enrollmentId() {
      let enrollmentId = 0;
      if (this.$route.query.enrollment_id) {
        enrollmentId = parseInt(this.$route.query.enrollment_id);
      }
      return enrollmentId;
    },
    userExamId() {
      let userExamId = null;
      if (this.$route.query.user_exam_id) {
        userExamId = parseInt(this.$route.query.user_exam_id);
      } else {
        userExamId = this.user_exam_id;
      }
      return userExamId;
    }
  },
  watch: {},

  mounted() {
    if (this.$route.query.enrollment_id) {
      this.getEnrollmentTest();
    } else if (this.$route.query.user_exam_id) {
      this.getExamTimer();
    }
  },

  methods: {
    async getEnrollmentTest() {
      let res = await enrollmentApi.getEnrollmentTest(this.enrollmentId);
      this.testType = res.type.toUpperCase();
      this.examId = res.exam_id;
      this.user_exam_id = res.user_exam_id;
      this.timer = this[this.testType];
      let timer = [];
      let timerSelect = this.timerSelect[this.testType];
      this.timer.forEach(e => {
        if(e.title !== "E") {
          timer.push({
            title: e.title,
            time: timerSelect[e.title].timer,
            rest: parseInt(e.restm) * 60 + parseInt(e.rests)
          });
        }
      });
      this.getTimer(this.testType, timer);
    },
    getExamTimer() {
      this.testType = this.$route.query.type.toUpperCase();
      this.timer = this[this.testType];
      let timer = [];
      let timerSelect = this.timerSelect[this.testType];
      this.timer.forEach(e => {
        if(e.title !== "E") {
          timer.push({
            title: e.title,
            time: timerSelect[e.title].timer,
            rest: parseInt(e.restm) * 60 + parseInt(e.rests)
          });
        }
      });
      this.getTimer(this.testType, timer);
    },
    getTimer(testType, timer) {
      this.testType = testType;
      this.timer = timer;
      this.startTest = 1;
    }
  }
};
</script>

<style scoped></style>
