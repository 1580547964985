<template>
  <div>
    <el-card class="box-card" v-if="page == 1">
      <div slot="header">
        <h4 style="margin-bottom:0">Timer</h4>
      </div>
      <div
        class="sat_instructions_withEssay instructions"
        v-if="type === 'SAT'"
      >
        <div class="setup">
          <h5 class="title-p">Setup:</h5>
          <ul>
            <li>
              <el-checkbox v-model="checkBox.one">
                Please make sure you have
                <strong >3 hours</strong> to do the test in
                <strong>1 sitting</strong>. Otherwise, your score will be
                cancelled.
              </el-checkbox>
            </li>
            <li>
              <el-checkbox v-model="checkBox.two">
                Please be in a quiet room with no distractions during this
                period of time.
              </el-checkbox>
            </li>
            <li>
              <el-checkbox v-model="checkBox.three">
                Please make sure you have the following in front of you:
              </el-checkbox>
              <ul>
                <li>The test booklet</li>
                <li>The answer sheet</li>
                <li>
                  A calculator (such as TI-84) for one of the math sections
                </li>
              </ul>
            </li>
            <li>
              <el-checkbox v-model="checkBox.four">
                Make sure the audio works by clicking here:
                <span class="bugle">
                  <el-button type="text" @click="$refs.audioTest.play()"
                    ><i
                      class="control_audio fa fa-volume-up"
                      aria-hidden="true"
                    ></i
                  ></el-button>
                  <audio
                    ref="audioTest"
                    src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/hint.mp3"
                  ></audio>
                </span>
              </el-checkbox>
            </li>
            <li>
              <el-checkbox v-model="checkBox.five">
                Set your phone/computer display to be “always on,” and make sure
                your computer’s screensaver is turned off.
              </el-checkbox>
            </li>
          </ul>
        </div>
        <div class="test_instructions">
          <p class="title-p">Test instructions:</p>
          <ol>
            <li>
              You will have a 10-minute break after sections 1, a 10-second break after sections 2, and a 5-minute break after sections 3.
            </li>
            <li>
              If you finish a section early, you may not turn to other sections.
              When the time is up, you need to stop working.
            </li>
          </ol>
        </div>
        <div class="chooseOne">
          <p class="title-p">Please choose one:</p>
          <el-alert :type="start > 0 ? 'success' : 'warning'" :closable="false">
            <el-radio v-model="start" label="1">
              I certify that I will follow all the instructions above.
            </el-radio>
            <el-radio v-model="start" label="2">
              I will not be able to follow the instructions, and I understand
              that I may need to redo a test if the score is not accurate.
            </el-radio>
          </el-alert>
        </div>
      </div>
      <div
        class="sat_instructions_withEssay instructions"
        v-if="type === 'ACT'"
      >
        <div class="setup">
          <h5 class="title-p">Setup:</h5>
          <ul>
            <li>
              <el-checkbox v-model="checkBox.one">
                Please make sure you have
                <strong v-if="hasEssay === '1'">4 hours and 30 minutes</strong>
                <strong v-else>4 hours</strong> to do the test in
                <strong>1 sitting</strong>. Otherwise, your score will be
                cancelled.
              </el-checkbox>
            </li>
            <li>
              <el-checkbox v-model="checkBox.two">
                Please be in a quiet room with no distractions during this
                period of time.
              </el-checkbox>
            </li>
            <li>
              <el-checkbox v-model="checkBox.three">
                Please make sure you have the following in front of you:
              </el-checkbox>
              <ul>
                <li>The test booklet</li>
                <li>The answer sheet</li>
                <li>
                  A calculator (such as TI-84) for one of the math sections
                </li>
              </ul>
            </li>
            <li>
              <el-checkbox v-model="checkBox.four">
                Make sure the audio works by clicking here:
              </el-checkbox>
              <span class="bugle">
                <el-button type="text" @click="$refs.audioTest.play()"
                  ><i
                    class="control_audio fa fa-volume-up"
                    aria-hidden="true"
                  ></i
                ></el-button>
                <audio
                  ref="audioTest"
                  src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/hint.mp3"
                ></audio>
              </span>
            </li>
            <li>
              <el-checkbox v-model="checkBox.five">
                Set your phone/computer display to be “always on,” and make sure
                your computer’s screensaver is turned off.
              </el-checkbox>
            </li>
          </ul>
        </div>
        <div class="test_instructions">
          <p class="title-p">Test instructions:</p>
          <ol>
            <li v-if="hasEssay === '1'">
              You will have a 10-minute break after section 2, and a 5-minute
              break after section 4. The essay after section 4 is mandatory.
            </li>
            <li v-else>
              You will have a 10-minute break after section 2.
            </li>
            <li>
              If you finish a section early, you may not turn to other sections.
              When the time is up, you need to stop working.
            </li>
          </ol>
        </div>
        <div class="chooseOne">
          <p class="title-p">Please choose one:</p>
          <el-alert :type="start > 0 ? 'success' : 'warning'" :closable="false">
            <el-radio v-model="start" label="1">
              I certify that I will follow all the instructions above.
            </el-radio>
            <el-radio v-model="start" label="2">
              I will not be able to follow the instructions, and I understand
              that I may need to redo a test if the score is not accurate.
            </el-radio>
          </el-alert>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="start text-center">
        <div v-if="startTest">
          <el-button @click="page++" type="success" :disabled="!startTest">
             Start the Timer
          </el-button>
        </div>
        <div v-else>
          <el-tooltip
            class="item"
            effect="dark"
            content="Please check all the boxes in System Check above first"
            placement="top"
          >
            <el-button type="info">
               Start the Timer
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" v-if="page == 2">
      <div slot="header">
        <h2 style="margin-bottom:0;" class="text-center">Timer</h2>
      </div>
      <!-- 考试计时页面 -->
      <div v-if="!isRest">
        <audio
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/begin_test.wav"
          @ended="startSection"
          autoplay
        />
        <div class="test_timer_screen text-center">
          <p>
            Please turn to
            <strong>
              section <span class="section_number">{{ index + 1 }}</span>
            </strong>
            of the test. You have
            <strong class="test_minutes">{{ timer[index].time / 60 }}</strong>
            minutes.
          </p>
          <p v-if="useCalculator" class="text-success">
            You may use the calculator for this section.
          </p>
          <p class="title-p p1">
            Time remaining:
          </p>
          <div class="countdown_timer">
            <div class="text-center">
              <button type="button" class="timer">
                <strong id="minute_show0">{{ time.minute }}</strong>
                <strong>:</strong>
                <strong id="second_show0">{{ time.second }}</strong>
              </button>
            </div>
          </div>
          <p class="title-p p1">
            Reminder:
          </p>
          <div class="tips">
            <ol class="text-left">
              <li>
                If you finish early, you may not turn not to other sections.
              </li>
              <li>
                When the time is up, you need to put down your pencil right
                away.
              </li>
              <li>
                If you are done and want to end the timer now, please click
                <el-button
                  type="text"
                  class="grading_student"
                  @click="toNextSection"
                  >here</el-button
                >
                to go to the next section.
              </li>
            </ol>
          </div>
          <div class="alert_sound">
            <audio :src="audio" autoplay></audio>
          </div>
        </div>
      </div>
      <div v-if="isRest">
        <audio
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/time_up.wav"
          @ended="nextSection"
          autoplay
        ></audio>
        <audio :src="restAudio" autoplay></audio>
        <div class="break_timer_screen text-center">
          <p class="title-p">
            Time is up! Please put down your pencil.
          </p>
          <p>
            <span v-if="nextUseCalculator" class="text-success">
              Please take out your calculator in the mean time.<br />
              You will need it for the next section.
            </span>
            <span v-else class="text-danger">
              Please do not take out your calculator.<br />
              You will not need it for the next section.
            </span>
          </p>
          <p class="title-p">
            Next section begins in:
          </p>
          <div class="countdown_timer">
            <div class="text-center">
              <button type="button" class="timer rest">
                <strong id="minute_show0">{{ time.minute }}</strong>
                <strong>:</strong>
                <strong id="second_show0">{{ time.second }}</strong>
              </button>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="text-center">
            <el-button @click="skipRest" type="success">
              Continue
            </el-button>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" v-if="page == 3">
      <div slot="header">
        <h4 style="margin-bottom:0">Timer</h4>
      </div>
      <h5 class="title-p">
        You have completed the test.
      </h5>
      <h5 class="title-p">
        Please click
        <el-button
          type="text"
          class="grading_student"
          @click="startGrading"
          style="font-size:20px"
          >here</el-button
        >
        to submit your test answers.
      </h5>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import enrollmentApi from "@/apis/enrollment";
export default {
  components: {},

  mixins: [],

  props: ["timer", "type", "hasEssay", "examId", "enrollmentId", "userExamId"],
  data() {
    return {
      startTest: false,
      start: "",
      page: 1,
      index: 0,
      isRest: false,
      time: {
        minute: "00",
        second: "00"
      },
      audio: "",
      restAudio: "",
      restTimer: {},
      testTimer: {},
      checkBox: {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false
      }
    };
  },
  computed: {
    nextUseCalculator() {
      let use = false;
      if (this.timer[this.index + 1].title == "MC") {
        use = true;
      }
      return use;
    },
    useCalculator() {
      let use = false;
      if (this.timer[this.index].title == "MC") {
        use = true;
      }
      return use;
    }
  },
  watch: {
    page(val) {
      if (val == 2) {
        this.showTime(this.timer[this.index].time);
      }
    },
    index() {
      this.showTime(this.timer[this.index].time);
    },
    isRest(val) {
      if (val) {
        this.showTime(this.timer[this.index].rest);
      }
    },
    checkBox: {
      handler(newName, oldName) {
        this.canStart();
      },
      deep: true
    },
    start() {
      this.canStart();
    }
  },

  mounted() {},
  methods: {
    canStart() {
      let canStart = true;
      for (let k in this.checkBox) {
        if (!this.checkBox[k]) {
          canStart = false;
        }
      }
      if (!this.start > 0) {
        canStart = false;
      }
      this.startTest = canStart;
    },
    startSection() {
      this.countDown(this.timer[this.index].time);
    },
    nextSection() {
      this.countDownRest(this.timer[this.index].rest);
    },
    countDown(seconds_remaining) {
      let vm = this;
      vm.showTime(seconds_remaining);
      this.testTimer = setInterval(() => {
        seconds_remaining--;
        vm.showTime(seconds_remaining);
        vm.setAudio(seconds_remaining);
        if (seconds_remaining < 0) {
          window.clearInterval(vm.testTimer);
          this.audio = "";
          if (this.index == this.timer.length - 1) {
            this.page++;
          } else {
            this.isRest = true;
          }
        }
      }, 1000);
    },
    countDownRest(seconds_remaining) {
      // secondes_remaining  = 10;
      let vm = this;
      vm.showTime(seconds_remaining);
      this.restTimer = setInterval(() => {
        seconds_remaining--;
        vm.showTime(seconds_remaining);
        if (seconds_remaining == 3) {
          this.restAudio =
            "https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/begin_next_section.wav";
        }
        if (seconds_remaining < 0) {
          window.clearInterval(this.restTimer);
          this.isRest = false;
          this.restAudio = "";
          this.index++;
        }
      }, 1000);
    },
    skipRest() {
      window.clearInterval(this.restTimer);
      this.isRest = false;
      this.restAudio = "";
      this.index++;
    },
    toNextSection() {
      window.clearInterval(this.testTimer);
      this.audio = "";
      if (this.index == this.timer.length - 1) {
        this.page++;
      } else {
        this.isRest = true;
      }
    },
    showTime(seconds_remaining) {
      let minute;
      let second;
      minute = Math.floor(seconds_remaining / 60);
      second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      this.time.minute = minute;
      this.time.second = second;
    },
    setAudio(seconds_remaining) {
      let test_type = this.type;
      let section = this.timer[this.index].title;
      if (test_type == "SAT") {
        if (section == "R") {
          if (seconds_remaining == 35 * 60) {
            this.audio =
              "https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/35_minutes.mp3";
          }
        }
        // 第二单元 剩20分钟提示
        if (section == "G") {
          if (seconds_remaining == 20 * 60) {
            this.audio =
              "https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/20_minutes.mp3";
          }
        }
        // 第四单元 剩35分钟提示
        if (section == "MC") {
          if (seconds_remaining == 35 * 60) {
            this.audio =
              "https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/35_minutes.mp3";
          }
        }
        // 第五单元 剩25分钟提示
        if (section == "E") {
          if (seconds_remaining == 25 * 60) {
            this.audio =
              "https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/25_minutes.mp3";
          }
        }
      }
      if (seconds_remaining == 5 * 60) {
        this.audio =
          "https://ivy-way.s3-ap-northeast-1.amazonaws.com/timer/5_minutes.wav";
      }
    },
    startGrading() {
      // if (this.enrollmentId > 0) {
      //   let user_exam_id = 0;
      //   // const result = await enrollmentApi.setEnrollmentTest(this.enrollmentId);
      //   user_exam_id = this.userExamId;
      //   console.log(this.userExamId > 0);
      //   // this.routerPush({
      //   //   name: "GradingPrivate",
      //   //   query: {
      //   //     user_exam_id: user_exam_id
      //   //   }
      //   // });
      // } else 
      if (this.userExamId > 0) {
        console.log(this.userExamId);
        let user_exam_id = this.userExamId;
        this.routerPush({
          name: "GradingPrivate",
          query: {
            user_exam_id: user_exam_id
          }
        });
      } else {
        this.routerPush({
          name: "Grading"
        });
      }
    }
  }
};
</script>

<style scoped>
.box-card {
  margin: 30px 0;
}
.test_timer_screen {
  text-align: center;
}
.subjects_choose h3,
.custom_choose h3,
.intro_screen p,
.custom_choose,
.subjects_choose,
.click_start_screen p,
.test_timer_screen p,
.break_timer_screen p,
.test_complete_screen p {
  font-size: 24px;
  color: black;
}
.countdown_timer {
  margin: 60px 0;
}
.title-p {
  font-weight: 500;
  color: black;
}
.timer {
  border: none;
  border-radius: 20px;
  background-color: #31b0d5;
  color: #fff;
  /* width: 60%; */
  padding: 0 25px;
  height: 200px;
  font-size: 180px;
  line-height: 200px;
}
.timer.rest {
  background-color: #ec971f;
}
@media screen and (max-width: 768px) {
  .subjects_choose h3,
  .custom_choose h3,
  .intro_screen p,
  .custom_choose,
  .subjects_choose,
  .click_start_screen p,
  .test_timer_screen p,
  .break_timer_screen p,
  .test_complete_screen p {
    font-size: 20px;
    color: black;
  }
  .countdown_timer {
    margin: 20px 0;
  }
  .text-left {
    direction: none;
  }
  .timer {
    height: 150px;
    font-size: 100px;
    line-height: 150px;
  }
  ol {
    padding-left: 20px;
  }
  .el-radio {
    white-space: unset;
  }
}
</style>
